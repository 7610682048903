import { CSSTransition } from 'react-transition-group';
import styles from "./modal.module.scss";

function Modal(props) {
    return (
        <CSSTransition
            in={props.in}
            timeout={200}
            classNames={{
                enter: styles.slideIn,
                enterActive: styles.slideInActive,
                enterDone: styles.slideInFinished,
                exitActive: styles.slideOutActive
            }}
            unmountOnExit
        >{props.children}</CSSTransition>
    );
}

export default Modal;
