import { Routes, Route } from "react-router-dom";
import Page from "./pages/Page";
import Item from "./pages/Item";
import Store from "./pages/Store";
import NotFound from "./pages/NotFound";
import Checkout from "./pages/Checkout";
import "./styles/global.scss";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Routes>
          <Route path="/:storeId" element={<Page><Store /></Page>} />
          <Route path="/:storeId/:branchId" element={<Page><Store /></Page>} />
          <Route path="/:storeId/:branchId/:itemId" element={<Page><Item /></Page>} />
          <Route path="/checkout" element={<Page><Checkout /></Page>} />
          <Route path="*" element={<Page><NotFound /></Page>} />
        </Routes>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;