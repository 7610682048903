import { Link } from "react-router-dom";
import styles from "./sizes.module.scss";

function Sizes(props) {
    const itemId = props.item;
    const storeId = props.store;
    const branchId = props.branch;
    const sizes = props.sizes;

    const close = (e, force) => {
        if (force || e.target === e.currentTarget) {
            props.close();
        }
    }

    return (
        <div className={styles.modal} onClick={(e) => close(e, false)}>
            <div className={styles.content}>
                <h3 className={styles.title}>Sizes<span className={styles.close} onClick={(e) => close(e, true)}>Close</span></h3>
                <ul className={styles.options}>
                    {sizes.map((option, key) =>
                        <Link to={`/${storeId}/${branchId}/${option.item}`} target="_self" onClick={(e) => close(e, true)} key={key}>
                            <li className={`${option.item === itemId ? styles.current : ""}`}>{option.label}</li>
                        </Link>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default Sizes;