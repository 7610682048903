import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { addItemToSelection, removeItemFromSelection, getSelection } from './useApi';

export const useAddItem = () => {
    const queryClient = useQueryClient();
    const addItem = useMutation(async (variables) => {
        const { itemId, webshop } = variables;
        await addItemToSelection(itemId, webshop);
    }, {
        onError: async (err) => {
            throw new Error(err);
        },
        onSuccess: async (data) => {
            queryClient.invalidateQueries(["selection"]);
            //queryClient.setQueryData(["selection"], data);
        }
    });

    return { addItem };
};

export const useRemoveItem = () => {
    const queryClient = useQueryClient();
    const removeItem = useMutation(async (variables) => {
        const { itemId } = variables;
        await removeItemFromSelection(itemId);
    }, {
        onError: async (err) => {
            throw new Error(err);
        },
        onSuccess: async (data) => {
            queryClient.invalidateQueries(["selection"]);
            //queryClient.setQueryData(["selection"], data);
        }
    });

    return { removeItem };
};

const useSelection = (storeId, branchId) => {
    const selection = useQuery(["selection"], () => getSelection(storeId, branchId));

    return {
        selection: selection.data,
        isLoading: selection.isLoading,
        refetch: selection.refetch,
    };
}

export default useSelection;