import { useParams } from "react-router-dom";
import { useState } from "react";
import Header from "../../layouts/Header";
import Scanner from "../../components/Scanner";
import Cart from "../../components/Cart";
import Modal from "../../components/Modal";
import styles from "./page.module.scss";

function Page({ children }) {
    const params = useParams();
    const [toggleScanner, setToggleScanner] = useState(false);
    const [toggleCart, setToggleCart] = useState(false);

    return (
        <>
            <button className={styles.scannerButton} onClick={() => setToggleScanner(!toggleScanner)}>
                <span className={`${styles.frame} ${styles.topLeft}`} />
                <span className={`${styles.frame} ${styles.topRight}`} />
                <span className={`${styles.frame} ${styles.bottomLeft}`} />
                <span className={`${styles.frame} ${styles.bottomRight}`} />
            </button>

            <Modal in={toggleScanner}><Scanner {...params} close={() => setToggleScanner(false)} /></Modal>

            <Modal in={toggleCart}><Cart {...params} close={() => setToggleCart(false)} /></Modal>

            <Header {...params} toggleScanner={() => setToggleScanner(!toggleScanner)} toggleCart={() => setToggleCart(!toggleCart)} />

            {children}
        </>
    );
}

export default Page;