import { useState } from "react";
import { Link } from "react-router-dom";
import useSelection from "../../utils/useSelection";
import styles from "./checkout.module.scss";
import CheckoutItem from "../../components/CheckoutItem";

function Checkout() {
    const { selection, isLoading } = useSelection();
    const [paymentMethod, setPaymentMethod] = useState('vipps');

    const selectOption = (e, method) => {
        document.getElementsByClassName(`${styles.method} ${styles.selected}`)[0].classList.remove(styles.selected);
        e.currentTarget.classList.toggle(styles.selected);
        setPaymentMethod(method);
    }

    if (isLoading) return null;

    console.log(paymentMethod);

    return (
        <div className={styles.checkout}>
            <div className={styles.content}>
                <h3 className={styles.title}>Webshop items</h3>
                <p className={styles.subtitle}>These items will be shipped to you</p>
                <div className={styles.items}>
                    {selection.items.filter((item) => item.webshop).map((item, key) => (
                        <CheckoutItem key={key} item={item} />
                    ))}
                </div>
            </div>
            <div className={`${styles.content} ${styles.highlighted}`}>
                <h3 className={styles.title}>Store items</h3>
                <p className={styles.subtitle}>Bring these items home with you today</p>
                <div className={styles.items}>
                    {selection.items.filter((item) => !item.webshop).map((item, key) => (
                        <CheckoutItem key={key} item={item} />
                    ))}
                </div>
            </div>

            <div className={styles.paymentMethods}>
                <h3 className={styles.title}>Payment method</h3>
                <div className={`${styles.method} ${paymentMethod === 'vipps' ? styles.selected : ''}`} onClick={(e) => selectOption(e, 'vipps')}><span className={styles.radio} />Vipps</div>
                <div className={`${styles.method} ${paymentMethod === 'klarna' ? styles.selected : ''}`} onClick={(e) => selectOption(e, 'klarna')}><span className={styles.radio} />Klarna</div>
            </div>

            <div className={styles.summary}>
                <Link to="/checkout" target="_self">
                    <button className={`${styles.continue} ${styles[paymentMethod]}`}>Continue to <span>{paymentMethod}</span></button>
                </Link>
            </div>
        </div>
    );
}

export default Checkout;