import styles from "./availability.module.scss";

function Availability(props) {
    const stores = props.stores;
    const current = props.current;

    const close = (e, force) => {
        if (force || e.target === e.currentTarget) {
            props.close();
        }
    }

    return (
        <div className={styles.modal} onClick={(e) => close(e, false)}>
            <div className={styles.content}>
                <h3 className={styles.title}>Availability<span className={styles.close} onClick={(e) => close(e, true)}>Close</span></h3>
                {stores.map((location, key) => (
                    <div className={`${styles.location} ${location.warehouse.id === current ? styles.current : null}`} key={`stock-${key}`}>
                        <span className={styles.warehouse}>{location.warehouse.name}</span>
                        <span className={styles.stock}>
                            <span className={`${styles.status} ${location.stock === 0 ? styles.outOfStock : (location.stock < 5 ? styles.fewLeft : styles.inStock)}`}></span>
                            {location.stock === 0 ? "Out of stock" : (location.stock < 5 ? "Few left" : "In stock")}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Availability;