import { useHtml5QrCode } from 'react-html5-qrcode-reader';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import styles from "./scanner.module.scss";
import { getItemBy } from '../../utils/useApi';

function Scanner(props) {
    const { Html5Qrcode } = useHtml5QrCode("../../../html5-qrcode.min.js");
    const navigate = useNavigate();
    const scanner = useRef(null);
    const [init, setInit] = useState(false);

    const handleCode = async (code) => {
        let itemId = code;

        if (!isNaN(code) && code.length === 13) {
            const item = await getItemBy("ean", code, "id");
            if (!item) {
                alert("Item not found");
                return;
            }
            itemId = item;
        }

        navigate(`/${props.storeId}/${props.branchId}/${itemId}`);
    }

    useEffect(() => {
        if (!init && Html5Qrcode) {
            setInit(true);
            scanner.current = new Html5Qrcode("reader");
            scanner.current.start({ facingMode: "environment" }, {
                fps: 10,
                aspectRatio: 1 / 1,
                qrbox: document.getElementById("reader").offsetWidth
            }, (data) => {
                scanner.current.stop();
                scanner.current.clear();
                props.close();
                handleCode(data);
            }, (err) => {
                console.log(err);
            }).catch(err => {
                console.log("Start failed", err);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Html5Qrcode, init]);

    const close = (e, force) => {
        if (force || e.target === e.currentTarget) {
            scanner.current.stop();
            scanner.current.clear();
            props.close();
        }
    }

    return (
        <div className={styles.scanner} onClick={(e) => close(e, false)}>
            <div className={styles.content}>
                <h3 className={styles.title}>Scan code<span className={styles.close} onClick={(e) => close(e, true)}>Close</span></h3>
                <div className={styles.container}>
                    <span className={`${styles.blur} ${styles.top}`} />
                    <span className={`${styles.blur} ${styles.left}`} />
                    <span className={`${styles.blur} ${styles.right}`} />
                    <span className={`${styles.blur} ${styles.bottom}`} />
                    <span className={`${styles.frame} ${styles.topLeft}`} />
                    <span className={`${styles.frame} ${styles.topRight}`} />
                    <span className={`${styles.frame} ${styles.bottomLeft}`} />
                    <span className={`${styles.frame} ${styles.bottomRight}`} />
                    <div id="reader" className={styles.reader}></div>
                </div>
            </div>
        </div>
    );
}

export default Scanner;