import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSelection, { useAddItem } from "../../utils/useSelection";
import styles from "./item.module.scss";
import Modal from "../../components/Modal";
import Availability from "../../components/Item/Availability";
import Sizes from "../../components/Item/Sizes";
import Variants from "../../components/Item/Variants";
import { getItem } from "../../utils/useApi";

function Item() {
    const { storeId, branchId, itemId } = useParams();
    const { selection, isLoading } = useSelection(storeId, branchId);
    const { addItem } = useAddItem();
    const [item, setItem] = useState(null);
    const [toggleSizes, setToggleSizes] = useState(false);
    const [toggleVariants, setToggleVariants] = useState(false);
    const [toggleAvailability, setToggleAvailability] = useState(false);
    const [swatch, setSwatch] = useState({});
    const [stock, setStock] = useState({});
    const [message, setMessage] = useState(null);

    useEffect(() => {
        async function fetchItem() {
            const item = await getItem(itemId, true);
            setItem(item);

            return item;
        }

        fetchItem().then((item) => {
            if (selection) {
                const swatch = JSON.parse(item.attributes.filter(attribute => attribute.label === "Swatch")[0].value);
                const store = item.stock.filter(location => location.warehouse.id === selection.store.branch.warehouseId)[0];
                const webshop = item.stock.filter(location => location.warehouse.webshop)[0];
                setSwatch(swatch);
                setStock({ store: store.stock, webshop: webshop.stock });
            }
        });

    }, [itemId, selection]);

    const isJson = (value) => {
        try {
            JSON.parse(value);
        } catch (e) {
            return false;
        }

        return true;
    }

    const addToCart = (itemId, webshop) => {
        addItem.mutate({
            itemId: itemId,
            webshop: webshop
        });

        const bagType = webshop ? "online" : "store";

        setMessage(`${item.name} was added to your ${bagType} bag!`);
    }

    useEffect(() => {
        if (message) {
            setTimeout(() => { setMessage(null); }, 3000);
        }
    }, [message]);

    if (!item || isLoading) return null;

    return (
        <div className={styles.item}>

            {message && <div className={styles.message}>{message}</div>}

            <div className={`${styles.details} ${styles.main}`}>
                <p className={styles.sku}>Art. no. {item.sku}</p>
                <h1 className={styles.name}>{item.name}</h1>
                <p className={styles.price}>{item.price} <span className={styles.currency}>kr</span></p>
            </div>

            <div className={styles.siblings}>
                <div className={styles.variant} onClick={() => { setToggleVariants(true) }}>
                    <span style={{ backgroundColor: swatch["color_hex"] }} />{item.variant}
                </div>
                <div className={styles.size} onClick={() => setToggleSizes(true)}>
                    {item.item}
                </div>
            </div>

            <div className={styles.availability} onClick={() => setToggleAvailability(true)}>
                <div className={`${styles.location} ${styles.current} ${stock.store === 0 ? styles.outOfStock : (stock.store < 5 ? styles.fewLeft : styles.inStock)}`}>
                    <span className={`${styles.status}`}></span>
                    {stock.store === 0 ? "Out of stock in store" : (stock.store < 5 ? "Few left in store" : "In stock in store")}
                </div>
                <div className={`${styles.location} ${styles.webshop} ${stock.webshop === 0 ? styles.outOfStock : (stock.webshop < 5 ? styles.fewLeft : styles.inStock)}`}>
                    <span className={`${styles.status}`}></span>
                    {stock.webshop === 0 ? "Unavailable online" : (stock.webshop < 5 ? "Few left online" : "In stock online")}
                </div>
            </div>

            <div className={styles.actions}>
                {stock.webshop > 0 && <button className={`${stock.store <= 0 ? styles.black : ''}`} onClick={() => addToCart(itemId, true)}>Buy online</button>}
                {stock.store > 0 && <button className={styles.black} onClick={() => addToCart(itemId, false)}>Buy in store</button>}
            </div>

            <div className={styles.details}>
                <div className={styles.accordion}>
                    <span className={styles.label}>Description</span>
                    <span className={styles.value} onClick={(e) => e.currentTarget.classList.toggle(styles.open)}>
                        {item.description}
                        {item.description.length > 150 && <span className={styles.overlay}></span>}
                    </span>
                </div>

                <div className={styles.attributes}>
                    {item.attributes.map((attribute, key) => {
                        if (attribute.display) {
                            return (
                                <div className={styles.accordion} key={`attribute-${key}`}>
                                    <span className={styles.label}>{attribute.label}</span>
                                    <span className={styles.value} onClick={(e) => e.currentTarget.classList.toggle(styles.open)}>
                                        {isJson(attribute.value) ? JSON.parse(attribute.value) : attribute.value}
                                        {attribute.value.length > 100 && <span className={styles.overlay}></span>}
                                    </span>
                                </div>
                            )
                        }
                        return null;
                    })}
                </div>

            </div>

            <div className={styles.media}>
                {item.media.map((media, key) => (
                    <div className={styles.mediaItem} key={`media-${key}`}>
                        <img src={media} alt={`${item.name}`} />
                    </div>
                ))}
            </div>

            <Modal in={toggleAvailability}>
                <Availability stores={item.stock} current={selection.store.branch.warehouseId} close={() => setToggleAvailability(false)} />
            </Modal>

            <Modal in={toggleVariants}>
                <Variants variants={item.siblings.variants} store={storeId} branch={branchId} item={item.id} close={() => setToggleVariants(false)} />
            </Modal>

            <Modal in={toggleSizes}>
                <Sizes sizes={item.siblings.sizes} item={item.id} store={storeId} branch={branchId} close={() => setToggleSizes(false)} />
            </Modal>

        </div>
    );
}

export default Item;