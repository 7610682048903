export const getSelection = async (storeId, branchId) => {
    const token = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);
    let params = {
        store: storeId,
        branch: branchId,
        includeStore: true
    };

    if (token !== null) {
        params.token = token;
    }

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/selection`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(params)
    });

    const selection = await response.json();

    if ("token" in selection) {
        localStorage.setItem(process.env.REACT_APP_KEY_TOKEN, selection.token);
    }

    return selection;
}

export const addItemToSelection = async (itemId, webshop) => {
    const token = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);

    if (token === null) {
        console.log("Token not found");
        return null;
    }

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/selection`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
            action: "add",
            token: token,
            item: itemId,
            webshop: webshop
        })
    });

    return await response.text();
}

export const removeItemFromSelection = async (itemId) => {
    const token = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/selection`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
            action: "remove",
            token: token,
            item: itemId,
        })
    });

    return await response.text();
}

export const getItem = async (itemId, includeSiblings) => {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/item`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
            method: "id",
            item: itemId,
            includeSiblings: includeSiblings
        })
    });

    return await response.json();
}

export const getItemBy = async (field, value, returnValue) => {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/item`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
            method: "field",
            field: field,
            value: value,
            returnValue: returnValue
        })
    });

    return await response.json();
}