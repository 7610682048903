import { useParams } from "react-router-dom";
import useSelection from "../../utils/useSelection";
import styles from "./store.module.scss";

function Store() {
    const { storeId, branchId } = useParams();
    const { selection, isLoading } = useSelection(storeId, branchId);

    if (isLoading) return null;

    return (
        <div className={styles.store}>
            <div className={styles.content}>
                <p className={styles.label}>Welcome to</p>
                {storeId && <h3 className={styles.store}>{selection.store.name}</h3>}
                {branchId && <p className={styles.branch}>{selection.store.branch.name}</p>}
            </div>
        </div>
    );
}

export default Store;