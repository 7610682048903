import styles from "./checkoutItem.module.scss";

function CheckoutItem(props) {
    const item = props.item;

    return (
        <div className={styles.checkoutItem}>
            <div className={styles.details}>
                <h4 className={styles.name}>
                    <span>{item.name}</span>
                    <span className={styles.meta}>{item.variant}, {item.item}, {item.price} kr</span>
                </h4>
            </div>
        </div>
    );
}

export default CheckoutItem;