import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./header.module.scss";
import useSelection from "../../utils/useSelection";

function Header(props) {
    const [scroll, setScroll] = useState(0);
    const { selection, isLoading } = useSelection(props.storeId, props.branchId);

    useEffect(() => {
        document.addEventListener("scroll", () => {
            if ((window.scrollY > 0) !== scroll) {
                setScroll(window.scrollY > 0);
            }
        });
    });

    if (isLoading) return null;

    return (
        <div className={`${styles.header} ${scroll ? styles.sticky : null}`}>
            <span />
            <div className={styles.store}>
                <Link to={`/${props.storeId}/${props.branchId}`} target="_self">
                    {selection.store.name} <span className={styles.branch}>{selection.store.branch.name}</span>
                </Link>
            </div>
            <button className={styles.button} onClick={props.toggleCart}>Cart</button>
        </div>
    );
}

export default Header;