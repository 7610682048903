import { Link } from "react-router-dom";
import styles from "./cartItem.module.scss";
import { useRemoveItem } from "../../utils/useSelection";

function CartItem(props) {
    const item = props.item;
    const { removeItem } = useRemoveItem();

    return (
        <Link to={`/${props.storeId}/${props.branchId}/${item.itemDocId}`} onClick={props.close}>
            <div className={styles.cartItem}>
                <img src={item.media[0]} alt={item.name} />
                <div className={styles.details}>
                    <h4 className={styles.name}>
                        <span>{item.name}</span>
                        <span className={styles.store}>{item.webshop ? "Webshop" : "Store"}</span>
                    </h4>
                    <p>{item.variant}, {item.item}</p>
                    <p>{item.price} kr</p>
                    <button className={styles.remove} onClick={() => { removeItem.mutate({ itemId: item.id }) }}>Remove</button>
                </div>
            </div>
        </Link>
    );
}

export default CartItem;