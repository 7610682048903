import { Link } from "react-router-dom";
import useSelection from "../../utils/useSelection";
import CartItem from "../CartItem";
import styles from "./cart.module.scss";

function Cart(props) {
    const { selection, isLoading } = useSelection();

    const close = (e, force) => {
        if (force || e.target === e.currentTarget) {
            props.close();
        }
    }

    if (isLoading) return null;

    return (

        <div className={styles.cart} onClick={(e) => close(e, false)}>
            <div className={styles.content}>
                <h3 className={styles.title}>Shopping bag<span className={styles.close} onClick={(e) => close(e, true)}>Close</span></h3>
                <div className={styles.items}>
                    {selection.items.map((item, key) => (
                        <CartItem key={key} item={item} {...props} />
                    ))}
                </div>
                <Link to="/checkout" target="_self" onClick={(e) => close(e, true)}>
                    <button className={styles.goToCheckout}>Continue to checkout</button>
                </Link>
            </div>
        </div>
    );
}

export default Cart;